
// cn.js
export default {
  home: {
    tab1: '案例展示',
    tab2: '产品定价',
    tab3: '常见疑问',
    lang: 'EN',
    title1: '通过 ',
    title2: 'MIYA GPT ',
    title3: 'Al 解决方案为您的未来赋能，打造更智能的明天',
    btn1: '立即开始免费体验',
    lRights1: 'MIYAIP',
    lRights2: 'STMD INC © 2023 All Rights Reserved ',
    lRights3: '苏ICP备09070171号-4',
    product1: 'AI聊天',
    productText1: '畅享无缝对话，MIYA GPT带您领略人工智能聊天的全新境界。',
    product2: 'AI绘画',
    productText2: '释放你的潜能，MIYA GPT 以你天马行空的想象力为船，以你惊人的创造力为浆，与你一起触摸梦想的边缘。',
    title5: '产品定价',
    titleText5: '不同的定价满足您不同的需求，为您带来更为自由的体验。（1RMB=1M值，不同充值额度享有不同优惠）',
    itemText1: '[ Default (GPT-3.5) ]',
    itemText2: '[ GPT-4 ]',
    itemText3: '[ Midjourney ]',
    unit: 'M值',
    label: '次',
    t1: '剧本写作',
    t2: '文字作答',
    t3: '超快的响应速度',
    t4: '回答更准确更接近事实',
    t5: '更加专业的创造力',
    t6: '智能化绘图',
    t7: '出图速度快',
    t8: '超强构图和色彩审美',
    t9: '风格化能力专业且丰富',
    title6: 'FAQ',
    titleText6: '为您解答常见一些常见的疑问。',
    lTel: '电话',
    lEmail: '邮箱',
    lTime: '在线时间',
    lAddress: '办公地址',
    lPolicy: '服务政策',
    home: '首页',
    goLogin: '登录/注册',
    goChatDraw: '聊天/绘画',
    fqa1: 'MIYA GPT是什么？',
    fqa11: 'MIYA GPT是基于ChatGPT最新版本构建的AI聊天系统。',
    fqa2: '我的数据是安全的吗？',
    fqa21: '我们非常尊重您的隐私。我们保证不会收集或保存任何您的个人信息或行为数据。 我们的产品或服务不会追踪您的浏览数据，无论是使用我们的网站还是我们的应用程序。我们不会记录您的浏览历史记录，搜索历史，点击记录等信息。我们也不会保存您的IP地址或设备信息。 我们的网站或应用程序不需要您提供任何个人信息进行注册或使用。如果您有任何疑虑或问题，欢迎随时与我们联系。我们将尽全力保护您的隐私，并确保我们的产品和服务符合最严格的隐私标准。',
  },
  operation: {
    title1: '操作',
    title2: 'AI模型',
    type1: 'AI聊天',
    type2: 'AI绘画',
    qq1: 'QQ交流群',
    qq2: 'QQ扫码关注',
    qq3: 'QQ频道',
    order: '充值记录',
    warn1: '历史数据过载，可能会影响使用，请及时保存数据清理记录！',
  },
  login: {
    close1: '点此区域关闭',
    title1: '登录',
    title2: '注册',
    title3: '重置密码',
    username: '手机号/邮箱',
    password: '密码',
    forgetPassword: '忘记密码',
    login: '登录',
    checkedText: '登录即同意我们的',
    checkedText1: '服务协议',
    checkedText2: '和',
    checkedText3: '隐私协议',
    checkedText4: '注册即代表同意',
    signup: '注册',
    phone: '手机号',
    phoneCode: '验证码',
    sendCode: '获取验证码',
    codeTip: '若未收到验证码，请查看短信垃圾箱',
    repeatPassword: '重复密码',
    placeholder4: '请输入邀请码（可留空）',
    errorPassword: '请输入密码',
    repeatPassword1: '两次输入密码不一致!',
    error1: '请阅读并同意服务条款',
    error2: '账号或密码错误，登录失败！',
    errorEmail: '请输入正确的账号',
    errorPhoneCode: '请输入正确的手机验证码',
    errorPhone: '请输入手机号',
    errorCaptcha: '请输入验证码',
    placeholder3: '请输入',
    btnOK: '确认',
    editPassword: '修改密码',
    balance: '账户余额',
    logout: '退出登录',
    success: '操作成功',
    sendSuccess: '发送成功',
    errorLogin: '请先登录系统',
    commit: '提交',
    agree: '同意',
    cancel: '取消',
    backHome: '返回首页',
    c1: '中国',
    c2: '美国',
    c3: '中国香港',
    c4: '中国澳门 ',
    c5: '中国台湾',
    c6: '日本',
    c7: '韩国',
    c8: '新加坡',
    c9: '马来西亚',
    c10: '菲律宾',
    c11: '泰国',
    c12: '印度',
    c13: '英国',
    c14: '德国',
    c15: '加拿大',
    c16: '法国',
    c17: '意大利',
    c18: '澳洲',
    c19: '新西兰',
    c20: '坦桑尼亚',
    c21: '加纳',
    c22: '肯尼亚',
    c23: '瑞典',
    c24: '埃及',
    c25: '秘鲁',
    c26: '玻利维亚',
    c27: '哥伦比亚',
    c28: '厄瓜多尔',
    c29: '墨西哥',
    c30: '越南',
    c31: '芬兰',
    c32: '荷兰',
  },
  ai: {
    modelFree:'以下模型均免费开放中...',
    tip1: '加入上下文',
    editName: '修改名称',
    copy: '复制',
    download: '下载图片',
    split: '图片分割',
    modelTip1: '扣费规则：',
    modelTip2: ' AI聊天',
    modelTip3: ' [Default (GPT-3.5)] ',
    modelTip4: '一个问题扣',
    modelTip5: 'M值，AI聊天',
    modelTip6: ' [GPT-4] ',
    modelTip7: '一个问题扣',
    modelTip8: 'M值，',
    modelTip9: 'AI绘画',
    modelTip10: ' [Midjourney] ',
    modelTip11: '画图一次扣 ',
    modelTip12: ' M值，不成功不扣除M值。', 
    modelTip13: ' [Claude-100K、Bard] ',
    modelTip14: '免费。',
    t1: '对话',
    t2: '绘画',
    config: 'Prompt设置',
    tip2: '禁止生成违法违规图片，一经发现将永久封禁账号！绘画关键词使用中文会生成失败，建议先使用GPT翻译为英文！',
    fx: '关键词万能公式',
    promptPlaceholder: '在此处输入您想要的对话风格，例如：我需要用一个比较官方的口吻与我对话',
    fxTitle: '关键词万能公式',
    fx1: '画面主体：',
    fx2: '元素背景：',
    fx3: '构图镜头：',
    fx4: '风格参考：',
    fx5: '图像设定：',
    fx6: '示例：',
    fx11: '告诉AI你要画一个什么？画面的主角，比如一个男孩在打篮球。',
    fx21: '给设定一个环境，地点，周围的物件，背景在哪里有什么。',
    fx31: '比如强调景深，物体位置，全身，半身，居中对齐，三视图，景深。',
    fx41: '艺术风格，艺术家的名字，作品的名字，例如包豪斯，宫崎骏等。',
    fx51: 'Midjourney的后缀词，ar/seed/iw/等等控制画面，还可增加：灯光/颜色/氛围/情绪/艺术形式。',
    fx61: 'AFute Chinese girl with brown eyes and long lighting Hayao Miyazaki style',
    fxTip: '让GPT翻译为英文生成更准确，注意关键词中间的空格，如果生成失败，请调整关键词。',
    balance: '余额',
    preferential: '优惠',
    discount: '折扣码',
    symbol: '￥',
    customAmountlabel: '会员专享套餐',
    customAmountplaceholder: '自定义金额 ￥1~￥10000 1:1.6M值',
    payNow: '立即购买',
    payType: '支付方式',
    payTip: '请扫码支付',
    payBtn: '支付完成',
    Alipay: '支付宝',
    WeChat: '微信',
    commingSoon: '联系客服开通',
    errorPayType: '请选择支付方式',
    inputPlaceholder: '请先新建或选择会话，然后再这里输入您的问题...',
    stop: '停止回答',
    stopDraw: '停止绘画',
    newChat: '新建聊天',
    newDraw: '新建绘画',
    new: '新建',
    new1: '共',
    new2: '条记录',
    my: '我的',
    wordCount: '字数：',
    send: '发送',
    drawLoading: '正在进行AI绘画，请稍等...',
    defaultChat: '你好，我是AI语言模型，我可以提供一些常用服务和信息，例如：<br/> 1. 翻译：我可以把中文翻译成英文，英文翻译成中文，还有其他一些语言翻译，比如法语、日语、西班牙语等。<br/>2. 咨询服务：如果你有任何问题需要咨询，例如健康、法律、投资等方面，我可以尽可能为你提供帮助。<br/>3. 闲聊：如果你感到寂寞或无聊，我们可以聊一些有趣的话题，以减轻你的压力。<br/>请告诉我你需要哪方面的帮助，我会根据你的需求给你提供相应的信息和建议。',
    defaultDraw: '暂无作品，去输入关键词开始绘画',
    emptyChat: '暂无聊天内容...',
    emptyDraw: '暂无绘画内容...',
    copy1: '复制成功',
    copy2: '复制失败',
    error3: '发生错误，请重新输入',
    error4: '当前使用已超过10次，请进入QQ频道输入如下代码后免费使用',
    drawInputPlaceholder: '请先新建或选择历史绘画，然后在这里输入问题，推荐使用英文输入',
    bandPhone: '绑定手机号'
  },
  order: {
    options:'操作',
    title1: '充值记录',
    all: '全部',
    noPay: '未支付',
    pay: '已支付',
    placeholderSearch1: '请输入搜索',
    orderId: '订单ID',
    consumptionId: '消费ID',
    orderName: '套餐名称',
    price: '金额',
    mi: 'M值',
    orderStatus: '订单状态',
    createTime: '订购时间',
    payTime: '支付时间',
    noData: '暂无数据',
    btnMi: 'M值充值',
    rmb: '元',
    deleteDialogTitle: '确认删除？',
    deleteDialogText: '删除后无法恢复',
    title2: '消费记录',
    useWay: '消费类型',
    usePrice: '消费金额',
    useTime: '消费时间',
  },
  invite: {
    inviteList: '邀请记录',
    t2: '邀请一个新人奖励 2 M值',
    inviteCode: '邀请码',
    text1: '已邀请', 
    text2: '个新人，获得奖励',
    text3: 'M值',
    table1: 'ID',
    table2: '被邀ID',
    table3: '奖励M值',
    table4: '获得时间',
    text4: '邀请一个新人奖励',
    text5: 'M值',
  },
  error: {
    e1: 'Midjourney图片发送次数已达每日（10次）上限,请隔日再进行操作',
    e2: '请先绑定手机号',
    e3: 'M值不足，请充值',
    e4: '图片正在加载，请稍后操作',
  },
  tos: {
    t1: 'MIYAGPT服务协议',
    t2: 'MIYAGPT平台隐私协议',
    t3: 'MIYAGPT平台法律合规指南',
    t4: 'MIYAGPT平台退款协议',
    c11: '甲方：平台用户',
    c12: '乙方：STMD INC',
    t1_1: '一、协议目的',
    c1_11: '本协议旨在明确甲方与乙方在使用MIYAGPT平台服务过程中的权利和义务，保障双方的合法权益。',
    t1_2: '二、服务内容',
    c1_21: '乙方通过MIYAGPT平台向甲方提供智能聊天助理服务，以满足甲方在问题求解、咨询交流等方面的需求。',
    c1_22: '乙方将根据甲方提供的信息和需求，以及已有的知识和技能进行回答和互动。',
    c1_23: '乙方将尽力提供准确、及时的信息和建议，但对于任何由于信息错误或不完整引起的后果，乙方不承担责任。',
    t1_3: '三、用户权益',
    c1_31: '甲方有权在使用MIYAGPT平台服务的过程中提出问题和需求，并接收乙方提供的回答和建议。',
    c1_32: '甲方的个人信息将受到严格保护，除非获得甲方明确授权或法律规定，乙方不会将其提供给任何第三方。',
    c1_33: '甲方有权随时终止使用MIYAGPT平台服务，并要求删除其个人信息，乙方将尽力满足甲方的请求。',
    t1_4: '四、用户义务',
    c1_41: '甲方在使用MIYAGPT平台服务时，应遵守国家法律法规，不得利用该平台从事任何违法违规行为。',
    c1_42: '甲方应保证提供给乙方的信息准确、完整，并对其产生的后果承担责任。',
    c1_43: '甲方应妥善保管其在MIYAGPT平台的账号和密码，不得将其提供给他人使用。',
    t1_5: '五、责任限制',
    c1_51: '乙方不对甲方在使用MIYAGPT平台服务中的任何损失或风险承担责任，包括但不限于财产损失、数据丢失等。',
    c1_52: '乙方不对因不可抗力、计算机病毒、黑客攻击等原因导致的服务中断或故障承担责任。',
    c1_53: '乙方不保证MIYAGPT平台服务的连续性、安全性和准确性，对于由此引起的任何后果不承担责任。',
    t1_6: '六、知识产权',
    c1_61: '乙方拥有MIYAGPT平台相关的知识产权和技术，未经乙方的明确授权，甲方不得复制、修改、传播或利用其它方式使用。',
    c1_62: '甲方在使用MIYAGPT平台服务过程中提供的问题、建议等相关信息，乙方有权进行整理和分析，并在保护用户隐私的前提下进行进一步利用。',
    t1_7: '七、协议变更与终止',
    c1_71: '本协议的约定可随时根据法律法规和乙方政策的变化进行调整，调整后的协议将在MIYAGPT平台上公示并生效。',
    c1_72: '如甲方违反本协议的约定，乙方有权随时终止向甲方提供MIYAGPT平台服务。',
    t1_8: '八、争议解决',
    c1_81: '本协议的解释和执行均适用中华人民共和国法律，双方在履行本协议过程中发生争议的，应通过友好协商解决；协商不成的，任何一方可向乙方所在地人民法院提起诉讼。',
    t1_9: '【重要提醒】',
    c1_91: '请您在使用MIYAGPT平台服务过程中不要涉及国家安全、个人敏感信息等内容，以免给自己和他人带来损失。',
    t2_1: '隐私政策',
    c2_11: '我们很重视您的隐私，并且致力于保护您个人信息的安全。为了更好地服务于您，我们提供以下隐私政策说明，希望您在使用我们的服务时能够更加放心。',
    t2_2: '适用范围',
    c2_21: '本隐私政策适用于我们的MIYAGPT平台及相关服务，包括但不限于：聊天机器人、语音识别、语音合成、智能问答等功能。',
    t2_3: '信息收集与使用',
    c2_31: '我们会收集您在使用我们服务时主动提供的信息或通过技术手段收集到的信息，如：',
    c2_32: '1.个人信息：包括但不限于姓名、性别、出生日期、职业、联系方式等。',
    c2_33: '2.设备信息：包括但不限于设备型号、操作系统版本、唯一设备标识符等。',
    c2_34: '3.日志信息：当您使用我们的服务时，我们会自动收集您的访问日志，包括但不限于IP地址、访问时间、浏览记录等。',
    c2_35: '我们将使用上述信息来提供服务、改进服务质量、保障服务安全以及进行市场调研和用户分析等用途。',
    t2_4: '信息共享与披露',
    c2_41: '我们承诺不会向第三方出售、出租或共享您的个人信息，除非经过您的授权或法律法规的规定。',
    c2_42: '我们会按照法律法规的规定，对外披露您的个人信息，包括但不限于以下情况：',
    c2_43: '1.经过您事先明确同意或授权的情况下；',
    c2_44: '2.根据法律法规等规定的要求向相关机构披露；',
    c2_45: '3.为了维护我们的合法权益而进行披露；',
    c2_46: '4.在紧急情况下保护其他用户或公众的安全。',
    t2_5: '信息存储与保护',
    c2_51: '我们承诺将采用合理的安全措施来保护您的个人信息安全。我们会采用加密技术、隔离技术等方式来保证您的个人信息的安全性和保密性。',
    c2_52: '但请注意，互联网并非绝对安全的环境，我们无法对绝对安全性做出保证。如果不幸发生数据泄露等事件，我们会及时启动应急预案，采取合理有效的措施防止事件扩大。',
    t2_6: '注销与修改',
    c2_61: '您可以随时申请停止使用我们的服务。同时，您也可以申请修改、更新或删除您的个人信息。为了保护您的隐私和安全，我们需要验证您的身份后再处理您的请求。',
    t2_7: '本政策的适用范围、内容和更新',
    c2_71: '本政策适用于MIYAGPT系统，包括但不限于聊天机器人、语音识别、语音合成、智能问答等功能。本政策的内容包括但不限于信息收集与使用、信息共享与披露、信息存储与保护、注销与修改等方面。我们可能会根据服务的改变、法律法规的规定或其他条件的变化而更新本政策，更新后的政策将公布在MIYAGPT系统上。',
    c2_72: '如有疑问或建议，请通过MIYAGPT平台内的反馈渠道联系我们。',
    t3_1: 'MIYAGPT平台法律合规指南',
    t3_2: '知识产权法：MIYAGPT的用户必须确保遵守美国知识产权法律，包括版权、商标和专利。尊重内容创作者的权利，在使用或复制版权材料之前获得适当的许可。',
    t3_3: '数据保护规定：在处理个人数据时，遵守美国的数据保护法律，如加利福尼亚消费者隐私法案（CCPA）和健康保险携带和责任法案（HIPAA）。为数据处理获取同意，并实施安全措施以保护敏感信息。',
    t3_4: '联邦贸易委员会（FTC）指南：遵守FTC关于广告和营销实践的真实性、公平性和透明度的指南。披露使用MIYAGPT生成的任何赞助内容或背书，以确保消费者透明度。',
    t3_5: '证券法：在生成有关金融市场、投资或证券的内容时，注意美国证券交易委员会（SEC）管理的证券法律。避免发表虚假或误导性声明，这些声明可能操纵股票价格或误导投资者。',
    t3_6: '反歧视法：避免生成基于种族、性别、宗教、残疾或年龄等受保护特征对个人或团体进行歧视的内容。确保遵守联邦反歧视法律，包括1964年民权法和美国残疾人法案（ADA）。',
    t3_7: '消费者保护法：遵守美国消费者保护法律，包括消费者保护法和州特定法规，以确保商业行为的公平、诚实和透明。与消费者互动时避免欺骗或不公平的贸易行为。',
    t3_8: "儿童在线隐私保护法（COPPA）：如果生成针对13岁以下儿童的内容，确保遵守COPPA规定，这要求在从儿童收集个人信息之前获取家长同意。",
    t3_9: '电信法：遵守联邦通信委员会（FCC）执行的管理电信服务的规定，包括对垃圾邮件、自动拨号电话和电话营销实践的限制。',
    t3_10: '可访问性标准：确保由MIYAGPT生成的数字内容符合美国残疾人法案（ADA）概述的可访问性标准，为残疾人提供平等的访问权限。',
    t3_11: '法律责任：用户对使用MIYAGPT生成的内容负责，可能因产生的任何非法或有害材料而承担法律责任。小心行事，进行尽职调查以避免法律后果。',
    t3_12: '通过遵守这些法律指南和规定，用户可以确保在使用MIYAGPT进行各种用途时，符合美国的法律和规定。',
    t4_1: '在MiyaGPT，我们致力于提供AI对话服务和客户满意度。我们了解可能存在客户并不完全满意的情况，因此，我们制定了以下退款政策：',
t4_2: '无退款：一般来说，由于我们产品和服务的数字性质，MiyaGPT上的所有销售均为最终销售，不可退款。这意味着，一旦您的购买完成并且服务已交付，我们通常不提供退款。',
t4_3: '特殊情况：尽管有上述规定，我们承认可能会有例外情况。如果您在使用我们的服务时遇到任何重大技术问题，并且这些问题直接归因于MiyaGPT，且在给予合理机会后我们无法解决，可能会发放退款。',
t4_4: '程序：如需在此类特殊情况下请求退款，请直接联系我们的客户服务团队，并提供您遇到问题的详细信息。我们将进行评估，决定是否根据此政策发放退款。',
t4_5: '管辖权：此退款政策应受美国法律的管辖并按其解释。任何与退款政策有关的争议应由位于美国的法院审理。',
t4_6: '政策更改：MiyaGPT保留随时根据本条款修改此退款政策的权利。如果我们对此退款政策进行更改，我们将在我们的网站上发布修订后的政策。',
t4_7: '我们强烈建议您在遇到任何服务问题时联系我们的客户支持团队寻求帮助。我们将在每一步为您提供帮助。',
t4_8: '通过购买我们的服务，您表示您接受此退款政策。如果您不同意此政策，请不要使用MiyaGPT服务。您继续使用MiyaGPT网站和服务，并对此政策的更改予以接受，将被视为您接受这些更改的证明。'
  }
}