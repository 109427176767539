import * as types from './mutation-types'

const mutations = {
  set_user (state, obj) {
    state.user = obj
  },
  set_pageWidth (state, obj) {
    state.pageWidth = obj
  },
  set_language (state, obj) {
    state.language = obj
  },
  set_storage (state, obj) {
    state.storage = obj
  },
  set_actionOperation (state, obj) {
    state.actionOperation = obj
  },
  set_createChat (state, obj) {
    state.createChat = obj
  },
  set_createDraw (state, obj) {
    state.createDraw = obj
  },
  set_sessionId (state, obj) {
    state.sessionId = obj
  },
  set_actionModel (state, obj) {
    state.actionModel = obj
  },
  set_historyChat (state, obj) {
    state.historyChat = obj
  },
  set_historyDraw (state, obj) {
    state.historyDraw = obj
  },
  set_isLoginGPT (state, obj) {
    state.isLoginGPT = obj
  },
}

export default mutations
