
import axios from 'axios'
import Cookies from 'js-cookie'
import router from '@/router'
import { Message } from 'element-ui'

export function getToken () {
  return Cookies.get('token')
}
export function setToken (token) {
  return Cookies.set('token', token)
}
export function removeToken() {
  return Cookies.remove('token')
}

export function logout () {
  removeToken()
  router.push('/login');
}
let http = axios.create({
  timeout: 1000 * 60 * 30,
  baseURL: window.baseUrl,
  withCredentials: false,  
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  return config
}, error => {
  return Promise.reject(error)
})


/**
 * 响应拦截
 */
http.interceptors.response.use(res => {  
  const code = res.data.code || 200;
  const message = res.data.message || '系统未知错误，请反馈给管理员'
  if (code === 401 && window.location.href.indexOf('/home') < 0 && window.location.href.indexOf('/login')) {
    Message.error('请重新登录')
    logout()
  } else {
    return res.data
  }
}, error => {
  let message = error.message
  console.log(message, error)
  if (message.indexOf('401') >= 0 && window.location.href.indexOf('/login') < 0 && window.location.href.indexOf('/home') < 0) {
    Message.error('请重新登录')
    logout()
  }
  return Promise.reject(error)
})

http.adornUrl = (actionName) => {
  return window.baseUrl + actionName
}

export default http

export function get (url, params) {
  let u = window.baseUrl.indexOf('localhost') >= 0 ? '/api/api' : '/api'
  return http(u + url, {
    method: 'get',
    params: params
  }).then(res => {
    return res
  })
}
export function post (url, data) {
  let u = window.baseUrl.indexOf('localhost') >= 0 ? '/api/api' : '/api'
  return http(u + url, {
    method: 'post',
    data: data
  }).then(res =>{
    return res
  })
}
export function getStatus (url, params) {
  let u = window.baseUrl.indexOf('localhost') >= 0 ? '/api' : ''
  return http(u + url, {
    method: 'get',
    params: params
  }).then(res => {
    return res
  }, error => {
    return res
  })
}

export function isMobile () {
  if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
    return true
  } else {
    return false
  }
}

