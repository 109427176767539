import { loadData } from '@/assets/js/cache.js'
import {getToken} from '@/assets/js/http.js'

const state = {
  user: {
    userName: null,
    userId: null,
  },
  pageWidth: 200,
  actionOperation: 'chat',
  createChat: false,
  createDraw: false,
  actionModel: '1',
  historyChat: null,
  historyDraw: null,
  isLoginGPT: false,
  sessionId: getToken() || '',
  language: loadData().save_language || 'CN',
  storage: loadData()
}

export default state
