
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const constantRouterMap = [  
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: resolve => require(['@/views/home/index.vue'], resolve),
  },
  { // 登录
    path: '/login',
    name: 'login',
    component: resolve => require(['@/views/login/login.vue'], resolve),
  }, 
  { // 注册
    path: '/signup',
    name: 'signup',
    component: resolve => require(['@/views/login/signup.vue'], resolve),
  }, 
  { // 重置密码
    path: '/resetPassword',
    name: 'resetPassword',
    component: resolve => require(['@/views/login/resetPassword.vue'], resolve),
  }, 
  {
    path: '/layout',
    component: resolve => require(['@/views/layout/index.vue'], resolve),
    children: [
      // { // 仪表板
      //   path: 'chat',
      //   name: 'chat',
      //   component: resolve => require(['@/views/chat/index.vue'], resolve),
      // },
      // { // 仪表板
      //   path: 'draw',
      //   name: 'draw',
      //   component: resolve => require(['@/views/draw/index.vue'], resolve),
      // },
    ]
  }, 
  {
    path: '*',
    component: resolve => require(['@/components/404.vue'], resolve)
  }
]

const router = new Router({
  mode:'history',
  // base: '/dist/',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

export default router