import state from "./state"

export const user = state => state.user
export const pageWidth = state => state.pageWidth
export const language = state => state.language
export const storage = state => state.storage
export const actionOperation = state => state.actionOperation
export const createChat = state => state.createChat
export const createDraw = state => state.createDraw
export const sessionId = state => state.sessionId
export const actionModel = state => state.actionModel
export const historyChat = state => state.historyChat
export const historyDraw = state => state.historyDraw
export const isLoginGPT = state => state.isLoginGPT