import '@babel/polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import '../public/config'

import '@adyen/adyen-web/dist/adyen.css';

import Clipboard from 'clipboard'
Vue.prototype.Clipboard = Clipboard//复制代码

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

import '@/assets/stylus/index.styl'
// import '../public/config'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/theme/theme/index.css'
Vue.use(ElementUI)

import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import hljs from 'highlight.js';
VMdEditor.use(githubTheme, {
  Hljs: hljs,
});
import createTipPlugin from '@kangc/v-md-editor/lib/plugins/tip/index';
import '@kangc/v-md-editor/lib/plugins/tip/tip.css';
VMdEditor.use(createTipPlugin());
import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index';
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css';
VMdEditor.use(createEmojiPlugin());
import createKatexPlugin from '@kangc/v-md-editor/lib/plugins/katex/cdn';
VMdEditor.use(createKatexPlugin());
import createMermaidPlugin from '@kangc/v-md-editor/lib/plugins/mermaid/cdn';
import '@kangc/v-md-editor/lib/plugins/mermaid/mermaid.css';
VMdEditor.use(createMermaidPlugin());
import createTodoListPlugin from '@kangc/v-md-editor/lib/plugins/todo-list/index';
import '@kangc/v-md-editor/lib/plugins/todo-list/todo-list.css';
VMdEditor.use(createTodoListPlugin());
// import createLineNumbertPlugin from '@kangc/v-md-editor/lib/plugins/line-number/index';
// VMdEditor.use(createLineNumbertPlugin());
import createHighlightLinesPlugin from '@kangc/v-md-editor/lib/plugins/highlight-lines/index';
import '@kangc/v-md-editor/lib/plugins/highlight-lines/highlight-lines.css';
VMdEditor.use(createHighlightLinesPlugin());
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';
VMdEditor.use(createCopyCodePlugin());
import createAlignPlugin from '@kangc/v-md-editor/lib/plugins/align';
VMdEditor.use(createAlignPlugin());
Vue.use(VMdEditor);


import cn from './assets/js/cn.js'
import en from './assets/js/en.js'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: 'CN',
  messages: {
    "CN": cn,
    "EN": en
  }
})

Vue.config.productionTip = false

import {get, post} from './assets/js/http.js'
Vue.prototype.$get = get
Vue.prototype.$post = post

import {mapGetters, mapActions} from 'vuex'
Vue.mixin({
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters(['language', 'storage', 'pageWidth'])
  },
  methods:{
    getData () {},
    ...mapActions(['saveStorage'])
  },
  watch: {
    $route () {
      this.getData()
    }
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')